import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSignIn from 'react-auth-kit/hooks/useSignIn';

import { toast } from 'sonner';

import { getToken } from '../../services/authService';

const SignIn = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const signIn = useSignIn();
    const navigate = useNavigate();

    const handleSignIn = async (e) => {
        // No default form submission
        e.preventDefault();

        // Validate against API
        try {
            const {token, currentUser} = await getToken(username, password);

            // Sign in
            if(signIn({
                auth: {
                    token: token,
                    type: 'Bearer'
                },
                userState: currentUser
            })) {
                toast.success("Successfully logged in.");
                navigate("/dashboard");
            } else {
                throw new Error("Sign in was not possible.");
            }
        } catch (error: any) {
            setPassword("");

            toast.error("Failed loggin in: " + error.message );
        }


    }

    return (
        <div className="w-screen h-screen bg-slate-100">
            <div
                className="w-full flex flex-col items-center justify-center px-6 pt-20 mx-auto pt:mt-0"
            >
                <a
                    href="/public"
                    className="flex items-center justify-center mb-8 text-2xl font-semibold lg:mb-10"
                >
                    <img
                        src="/images/cl-logo-small-transparent.png"
                        alt="Cygna Labs Logo"
                        className="mr-4 h-11"
                    />
                </a>

                <div className="w-full max-w-md p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow">
                    <h2 className="text-2xl font-bold text-gray-700">
                        Sign in
                    </h2>
                    <form className="mt-8 space-y-6" onSubmit={handleSignIn}>
                        <div>
                            <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900"
                            >Account Name</label
                            >
                            <input
                                type="text"
                                name="username"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                                placeholder="@cygnauser"
                                required
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="password"
                                className="block mb-2 text-sm font-medium text-gray-900"
                            >Password</label
                            >
                            <input
                                type="password"
                                name="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="••••••••••••"
                                className="bg-gray-50 border tracking-widest border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                                required
                            />
                        </div>
                        <div className="flex items-center justify-start">
                            <button
                                type="submit"
                                className="w-full px-5 py-3 text-base font-medium text-center text-white bg-orange-500 rounded-lg hover:bg-orange-600 focus:ring-4 focus:ring-orange-300 sm:w-auto"
                            >Sign In
                            </button
                            >
                        </div>
                        <div className="text-sm font-medium text-gray-400">
                            Not registered? <a
                            href="/auth/sign-up"
                            className="text-primary-700 hover:underline cursor-pointer text-blue-900"
                        >Create account</a
                        >
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SignIn;