import axios from "axios";

export async function getToken(username: string, password: string) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_CONTROLLER_URL}/token`,
            {
                grant_type: "password",
                scope: "",
                client_id: "",
                client_secret: "",
                username: username,
                password: password
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                }
            }
        );

        const token = response.data.access_token;
        if(! token){
            throw new Error("No token has been returned.");
        }

        const currentUser = response.data.current_user;
        if(! currentUser){
            throw new Error("No user has been returned.")
        }

        return { token, currentUser };
    } catch (error) {
        throw new Error(error.response?.data?.detail || error.message)
    }
}
