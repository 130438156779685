import React from 'react';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'sonner';

import { isCodeValid, createUserWithCode } from '../../services/inviteService.ts';
import { isPasswordValid } from '../../services/utilService.ts';

import {
    Label,
    TextInput
} from "flowbite-react";

const SignUp = () => {
    const [code, setCode] = useState(Array(6).fill(""))
    const inputRefs = useRef([]);

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")

    const [userName, setUserName] = useState("")
    const [email, setEmail] = useState("")

    const [password, setPassword] = useState("")
    const [passwordRepeat, setPasswordRepeat] = useState("")

    const navigate = useNavigate();

    const handleSignUp = async (e) => {
        e.preventDefault();

        console.log(code.join(""))

        const codeValid = isCodeValid(code.join(""));
        if( !codeValid ){
            toast.error("Your invite code is invalid.")
            return;
        }

        const passwordValid = isPasswordValid(password, passwordRepeat);
        if( !passwordValid){
            toast.error("Your password does not match the criteria.")
            return;
        }

        if (!firstName.length || !lastName.length || !userName.length || !email.length) {
            toast.error("Creating a user requires Name, Email and Password.");
            return;
        }

        const data = {
            first_name: firstName,
            last_name: lastName,
            email: email,

            username: userName,
            password: password,

        }

        try {
            await createUserWithCode(code.join(""), data);
            navigate("/auth/sign-in");
            toast.success("User has been created!")
        } catch (error: any) {
            toast.error("Failed creating user: " + error)
        }
    }

    const handleCodeInputChange = (e : React.ChangeEvent<HTMLInputElement>, index : number) => {
        const value = e.target.value;

        if(value.match(/^[0-9]*$/)){
            // Copy code array, edit it then set it again
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            // If there is a next element, focus that
            if(value && index < 5){
                inputRefs.current[index + 1].focus();
            }
        }
    }

    const handleKeyDown = (e : React.KeyboardEvent<HTMLInputElement>, index : number) => {
        if(e.key === "Backspace" && !code[index]) {
            // Copy and update code
            const newCode = [...code];
            newCode[index] = "";
            setCode(newCode);

            // Focus element behind if there is any
            if (index > 0){
                inputRefs.current[index - 1].focus();
            }
        }
    }

    const handlePaste = (e : React.ClipboardEvent<HTMLInputElement>) => {
        const pasteData = e.clipboardData.getData('Text').slice(0, 6);
        const newCode = pasteData.split("").concat(Array(6-pasteData.length).fill(''));
        setCode(newCode);

        newCode.forEach((char, idx) => {
            inputRefs.current[idx].value = char;
        });
        inputRefs.current[Math.min(pasteData.length, 5)].focus();
        e.preventDefault();
    }

    return (
        <div className="w-screen h-screen bg-slate-100">
            <div
                className="w-full flex flex-col items-center justify-center px-6 pt-20 mx-auto pt:mt-0"
            >
                <a
                    href="/public"
                    className="flex items-center justify-center mb-8 text-2xl font-semibold lg:mb-10"
                >
                    <img
                        src="/images/cl-logo-small-transparent.png"
                        alt="Cygna Labs Logo"
                        className="mr-4 h-11"
                    />
                </a>

                <div className="w-full max-w-2xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow">
                    <h2 className="text-2xl font-bold text-gray-700">
                        Sign up
                    </h2>
                    <div className="flex items-center justify-center min-w-full  pb-6 border-b border-gray-200">
                        <div>
                            <Label htmlFor="code-0">Invitation Code</Label>
                            <div className="mt-1 flex items-center justify-center space-x-4">
                                { code.map((char, index) => (
                                    <TextInput
                                        theme={{ field: { input: { base: "block w-full border text-lg disabled:cursor-not-allowed disabled:opacity-50 text-center" } } }}
                                        className="w-12 h-12"
                                        key={0}
                                        id="code-0"
                                        name="code-0"
                                        value={char}
                                        maxLength={1}
                                        onChange={(e) => handleCodeInputChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        onPaste={handlePaste}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 -pt-4 sm:grid-cols-2">
                        <div>
                            <Label htmlFor="firstName">First name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="firstName"
                                    name="firstName"
                                    placeholder="Bonnie"
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="lastName">Last name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Green"
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="username">Username</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="username"
                                    name="username"
                                    placeholder="@cygnauser"
                                    value={userName}
                                    onChange={e => setUserName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="email">Email</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="email"
                                    name="email"
                                    placeholder="example@company.com"
                                    type="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 border-t border-gray-100 mt-9 pt-2">
                        <div>
                            <Label htmlFor="passwordNew">New password</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="passwordNew"
                                    name="passwordNew"
                                    placeholder="••••••••"
                                    type="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="passwordRepeat">Repeat password</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="passwordRepeat"
                                    name="passwordRepeat"
                                    placeholder="••••••••"
                                    type="password"
                                    value={passwordRepeat}
                                    onChange={e => setPasswordRepeat(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-start">
                            <button
                                onClick={handleSignUp}
                                className="w-full px-5 py-3 text-base font-medium text-center text-white bg-orange-500 rounded-lg hover:bg-orange-600 focus:ring-4 focus:ring-orange-300 sm:w-auto"
                            >Sign Up
                            </button
                            >
                        </div>
                        <div className="text-sm font-medium text-gray-400">
                            Already have an account? <a
                            href="/auth/sign-in"
                            className="text-primary-700 hover:underline cursor-pointer text-blue-900"
                        >Sign in</a
                        >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;