import axios from "axios";

import { UserCreate, UserUpdate } from './utilService';

export async function getUsers(authHeader: string, queryString: string = null): Promise<any[]> {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/user/`,
            {
                params: {
                    query: queryString,
                },
                headers: {
                    'Accept': 'application/json',
                    'Authorization': authHeader,
                }
            }
        );

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.detail || error.message);
    }
}

export async function createUser(authHeader: string, data: UserCreate) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/user`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': authHeader,
                }
            }
        );
    } catch (error) {
        throw new Error(error.response?.data?.detail || error.message);
    }
}

export async function updateUser(authHeader: string, user_id: string, data: UserUpdate) {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/user/${user_id}`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': authHeader,
                }
            }
        );

        console.log(response)
    } catch (error: any) {
        throw new Error(error.response?.data?.detail || error.message);
    }
}

export async function deleteUser(authHeader: string, user_id: string) {
    try {
        await axios.delete(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/user/${user_id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': authHeader,
                }
            }
        );
    } catch (error: any) {
        throw new Error(error.response?.data?.detail || error.message)
    }
}