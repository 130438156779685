import React from 'react';
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

import {toast} from 'sonner';

import {
    Avatar,
    Button,
    Dropdown,
    Label,
    Modal,
    Navbar as NavbarFlowbite,
    TextInput,
    ToggleSwitch
} from 'flowbite-react';

import {isPasswordValid, UserUpdate} from "../services/utilService";
import {updateUser} from "../services/userService";

const Navbar = () => {
    const signOut = useSignOut();
    const auth: any = useAuthUser();
    const navigate = useNavigate();

    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if(!auth || !auth["first_name"]){
            navigate("/auth/sign-in");
            toast.error("Your session has expired.")
        }
    }, [auth])

    return (
        <NavbarFlowbite fluid rounded>
            <NavbarFlowbite.Brand>
                <img src="/images/cl-logo-small-transparent.png" className="ml-3 h-8" alt="Cygna Labs Logo"/>
            </NavbarFlowbite.Brand>
            <div className="flex md:order-2 pr-2">
                <Dropdown
                    arrowIcon={false}
                    inline
                    label={
                        <Avatar alt="User settings"
                                placeholderInitials={auth.first_name.charAt(0).toUpperCase() + auth.last_name.charAt(0).toUpperCase()}
                                rounded/>
                    }
                >
                    <Dropdown.Header>
                        <span className="block text-sm">{auth.first_name + " " + auth.last_name}</span>
                        <span className="block truncate text-sm font-medium">{auth.email}</span>
                    </Dropdown.Header>
                    <Dropdown.Item onClick={() => setModalOpen(true)}>Profile</Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Item onClick={
                        () => {
                            signOut();
                            navigate("/auth/sign-in");
                            toast.success("Successfully logged out")
                        }
                    }>Sign out</Dropdown.Item>
                </Dropdown>
                <NavbarFlowbite.Toggle/>
            </div>
            {isModalOpen === true && <EditProfileModal isOpen={isModalOpen} setOpen={setModalOpen} />}
        </NavbarFlowbite>
    )
}


const EditProfileModal = ({isOpen, setOpen}) => {
    const authHeader = useAuthHeader();
    const authUser = useAuthUser();

    const [firstName, setFirstName] = useState(authUser["first_name"]);
    const [lastName, setLastName] = useState(authUser["last_name"]);
    const [userName, setUserName] = useState(authUser["username"]);
    const [email, setEmail] = useState(authUser["email"]);
    const [position, setPosition] = useState(authUser["meta"] ? authUser["meta"]["position"] : "");
    const [country, setCountry] = useState(authUser["meta"] ? authUser["meta"]["country"] : "");
    const [administrator, setAdministrator] = useState(authUser["administrator"]);
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');


    const onProfileUpdate = async (e: any) => {
        e.preventDefault();

        const data: UserUpdate = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            username: userName,
            administrator: administrator,
            meta: {
                position: position,
                country: country
            }
        };


        // Only set password if it isn't empty
        if (password !== "") {
            const passwordValid = isPasswordValid(password, passwordRepeat);
            if (!passwordValid) {
                toast.error("Password does not match the criteria.")
                return;
            }

            data["password"] = password;
        }
        try {
            await updateUser(authHeader, authUser["_id"], data).then(() => toast.success("User has been updated."));
        } catch (error) {
            toast.error("Failed updating user: " + error.message);
        }

        // Close Modal and re-render Parent Components
        setOpen(false);

        window.location.reload();
    }


    return (
        <Modal onClose={() => setOpen(false)} show={isOpen}>
            <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
                <strong>Edit your profile</strong>
            </Modal.Header>
            <Modal.Body>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                    <div>
                        <Label htmlFor="firstName">First name</Label>
                        <div className="mt-1">
                            <TextInput
                                id="firstName"
                                name="firstName"
                                placeholder="Bonnie"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div>
                        <Label htmlFor="lastName">Last name</Label>
                        <div className="mt-1">
                            <TextInput
                                id="lastName"
                                name="lastName"
                                placeholder="Green"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}

                            />
                        </div>
                    </div>
                    <div>
                        <Label htmlFor="username">Username</Label>
                        <div className="mt-1">
                            <TextInput
                                disabled
                                id="username"
                                name="username"
                                type="text"
                                value={userName}
                                onChange={e => setUserName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div>
                        <Label htmlFor="email">Email</Label>
                        <div className="mt-1">
                            <TextInput
                                id="email"
                                name="email"
                                placeholder="example@company.com"
                                type="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    <div>
                        <Label htmlFor="position">Position</Label>
                        <div className="mt-1">
                            <TextInput
                                id="position"
                                name="position"
                                placeholder="Network Administrator"
                                value={position}
                                onChange={e => setPosition(e.target.value)}
                            />
                        </div>
                    </div>
                    <div>
                        <Label htmlFor="country">Country</Label>
                        <div className="mt-1">
                            <TextInput
                                id="country"
                                name="country"
                                placeholder="United States"
                                value={country}
                                onChange={e => setCountry(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex space-x-2 items-center">
                        <ToggleSwitch checked={administrator} onChange={setAdministrator}
                                      disabled={!authUser["administrator"]} label="Administrator"/>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 border-t border-gray-100 mt-9 pt-2">
                    <div>
                        <Label htmlFor="passwordNew">New password</Label>
                        <div className="mt-1">
                            <TextInput
                                id="passwordNew"
                                name="passwordNew"
                                placeholder="••••••••"
                                className="tracking-widest"
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div>
                        <Label htmlFor="passwordRepeat">Repeat password</Label>
                        <div className="mt-1">
                            <TextInput
                                id="passwordRepeat"
                                name="passwordRepeat"
                                placeholder="••••••••"
                                className="tracking-widest"
                                type="password"
                                value={passwordRepeat}
                                onChange={e => setPasswordRepeat(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex space-x-3">
                    <Button color="blue" onClick={onProfileUpdate}>
                        Save all
                    </Button>
                    <Button color="red" outline onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>

    );
};

export default Navbar;