import React from 'react';
import {useEffect, useState} from 'react';
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {toast} from "sonner";
import {useNavigate} from "react-router-dom";

import {Badge, Breadcrumb, Button, Label, Modal, Select, Spinner, Table, TextInput, ToggleSwitch} from "flowbite-react";
import {
    HiBan,
    HiCheck,
    HiHome,
    HiGlobe,
    HiArrowSmRight,
    HiPlus,
    HiOutlinePencilAlt, HiTrash, HiOutlineExclamationCircle,
} from "react-icons/hi";

import { getDomains, createDomain, updateDomain, deleteDomain } from '../../services/domainService';

import NavbarSidebarLayout from "../../layouts/NavbarSidebarLayout";

const Domains = () => {
    const authHeader = useAuthHeader();
    const navigate = useNavigate();

    const [domainSearchQuery, setDomainSearchQuery] = useState('');
    const [domainList, _setDomainList] = useState([]);


    const setDomainList = (data: any) => {
        _setDomainList(data.sort((a: any, b: any) => a.name.localeCompare(b.name)))
    }


    const intervalTime = 10000;

    useEffect(() => {
        const updateDomains = () => {
            getDomains(authHeader, null)
                .then(result => setDomainList(result))
                .catch(() => {
                    navigate("/auth/sign-in");
                    toast.error("Your session has expired.")
                });
        }

        updateDomains();

        const intervalId = setInterval(updateDomains, intervalTime);

        return () => clearInterval(intervalId)
    }, [authHeader, intervalTime])

    const onDomainSearchChange = (e: any) => {
        setDomainSearchQuery(e.target.value);
        getDomains(authHeader, e.target.value).then(result => setDomainList(result));
    }

    return (
        <NavbarSidebarLayout isFooter={false}>
            <div className="block items-center justify-between border-b border-gray-200 bg-white p-4">
                <div className="mb-1 w-full">
                    {/* Breadcrumbs */}
                    <div className="mb-4">
                        <Breadcrumb className="mb-4">
                            <Breadcrumb.Item href="/dashboard/domains">
                                <div className="flex items-center gap-x-3">
                                    <HiHome className="text-xl"/>
                                    <span>Dashboard</span>
                                </div>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Domains</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1 className="text-xl font-semibold text-gray-900">All domains</h1>
                    </div>

                    {/* Controls */}
                    <div className="flex justify-between items-center">
                        <form className="pr-3">
                            <div className="relative mt-1 lg:w-64 xl:w-96">
                                <TextInput id="users-search" name="users-search" placeholder="Search for domains"
                                           value={domainSearchQuery} onChange={onDomainSearchChange}/>
                            </div>
                        </form>
                        <div className="flex space-x-4 items-center">
                            <AddDomainModal setDomainList={setDomainList} />
                        </div>
                    </div>

                </div>
            </div>

            <div className="flex flex-col">
                <div className="overflow-x-auto">
                    <div className="inline-block min-w-full align-middle">
                        <div className="overflow-hidden shadow w-full">
                            <Table className="min-w-full divide-y divide-gray-200">
                                <Table.Head className="bg-gray-100">
                                    <Table.HeadCell>Name</Table.HeadCell>
                                    <Table.HeadCell>Active</Table.HeadCell>
                                    <Table.HeadCell>GeoRouting</Table.HeadCell>
                                    <Table.HeadCell>View Records</Table.HeadCell>
                                    <Table.HeadCell>Actions</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y divide-gray-200 bg-white">
                                    {
                                        domainList.length !== 0 && domainList.map(domain => (
                                            <DomainInRow domain={domain} key={domain.id}
                                                         setDomainList={setDomainList}/>
                                        ))
                                    }
                                </Table.Body>
                            </Table>
                            {
                                domainList.length === 0 &&
                                <>
                                    <div className="flex w-full h-full items-center justify-center pt-20">
                                        <Spinner size="xl"/>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </NavbarSidebarLayout>
    );
}

const DomainInRow = ({ domain, setDomainList }) => {
    const navigate = useNavigate();

    return (
        <>
            <Table.Row className="hover:bg-gray-100">
                <Table.Cell>
                    <div className="text-sm font-normal text-gray-500">
                        <div className="text-base font-semibold text-gray-900">
                            <span>{domain.name}</span>
                        </div>
                        <div className="flex items-center space-x-1 text-sm font-normal text-gray-500">
                            <HiGlobe/>
                            <span>{domain.domain_name}</span>
                        </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    {
                        domain.active ?
                            <div className="w-min">
                                <Badge icon={HiCheck} color="green">Active&nbsp;</Badge>
                            </div>
                            :
                            <div className="w-min">
                                <Badge icon={HiBan} color="red">Inactive&nbsp;</Badge>
                            </div>
                    }
                </Table.Cell>
                <Table.Cell>
                    {
                        domain.geo_routing_enabled ?
                            <div className="w-min">
                                <Badge icon={HiCheck} color="green">Enabled&nbsp;</Badge>
                            </div>
                            :
                            <div className="w-min">
                                <Badge icon={HiBan} color="red">Disabled&nbsp;</Badge>
                            </div>
                    }
                </Table.Cell>
                <Table.Cell>
                    <Button color="gray" onClick={() => {navigate(`/dashboard/domain/${domain._id}/records`)}}>
                        <div className="flex items-center space-x-1">
                            <span>View Records</span>
                            <HiArrowSmRight />
                        </div>
                    </Button>
                </Table.Cell>
                <Table.Cell>
                    <div className="flex items-center justify-start space-x-5 whitespace-nowrap">
                        <EditDomainModal domain={domain} setDomainList={setDomainList} />
                        <DeleteDomainModal domain={domain} setDomainList={setDomainList} />
                    </div>
                </Table.Cell>
            </Table.Row>
        </>
    )
}

const AddDomainModal = ({ setDomainList }) => {
    const authHeader = useAuthHeader();
    const navigate = useNavigate();

    const [isOpen, setOpen] = useState(false);

    const [name, setName] = useState('')
    const [domainName, setDomainName] = useState('')

    const [loadBalancingStrategy, setLoadBalancingStrategy] = useState('MERSENNE')

    const [active, setActive] = useState(false);
    const [geoRoutingEnabled, setGeoRoutingEnabled] = useState(false);

    const onDomainCreate = async (e: any) => {
        e.preventDefault();

        const data = {
            name: name,
            domain_name: domainName,
            load_balancing_strategy: loadBalancingStrategy,
            active: active,
            geo_routing_enabled: geoRoutingEnabled
        }

        createDomain(authHeader, data)
            .then(() => {
                toast.success("Domain has been created.");
                setOpen(false);

                // Update domain list
                getDomains(authHeader, null)
                    .then(result => setDomainList(result))
                    .catch(() => {
                        navigate("/auth/sign-in");
                        toast.error("Your session has expired.");
                    })
            }).catch(error => {
                toast.error("Failed creating domain: " + error.message);
            })
    }

    return (
        <>
            <Button color="blue" onClick={() => setOpen(true)}>
                <div className="flex items-center gap-3">
                    <HiPlus className="text-xl"/>
                    Add Domain
                </div>
            </Button>
            <Modal onClose={() => setOpen(false)} show={isOpen}>
                <Modal.Header className="border-b border-gray-200 !p-6">
                    <strong>Add Domain</strong>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div>
                            <Label htmlFor="name">Name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="name"
                                    name="name"
                                    placeholder="Display Name"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="domainName">Domain Name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="domainName"
                                    name="domainName"
                                    placeholder="example.com"
                                    value={domainName}
                                    onChange={e => setDomainName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-9 pt-2">
                        <div>
                            <Label htmlFor="load-balancing-strategy">Load-Balancing Strategy</Label>
                            <div className="mt-1">
                                <Select id="load-balancing-strategy" required value={loadBalancingStrategy} onChange={(e) => setLoadBalancingStrategy(e.target.value)}>
                                    <option value="MERSENNE">Mersenne</option>
                                    <option value="ROUND_ROBIN">Round Robin</option>
                                    <option value="LOWEST_RESPONSE_TIME">Lowest Response Time</option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-9 pt-2">
                        <div className="pt-4 flex flex-row space-x-4 justify-center items-center">
                            <ToggleSwitch checked={active} label="Active" onChange={setActive}/>
                            <ToggleSwitch checked={geoRoutingEnabled} label="Geo-Routing"
                                          onChange={setGeoRoutingEnabled}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex space-x-3">
                        <Button color="blue" onClick={onDomainCreate}>
                            Create
                        </Button>
                        <Button color="red" outline onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const EditDomainModal = ({ domain, setDomainList }) => {
    const authHeader = useAuthHeader();
    const navigate = useNavigate();

    const [isOpen, setOpen] = useState(false);

    const [name, setName] = useState(domain.name)
    const [domainName, setDomainName] = useState(domain.domain_name)

    const [loadBalancingStrategy, setLoadBalancingStrategy] = useState(domain.load_balancing_strategy)

    const [active, setActive] = useState(domain.active);
    const [geoRoutingEnabled, setGeoRoutingEnabled] = useState(domain.geo_routing_enabled);

    const onDomainUpdate = async (e: any) => {
        e.preventDefault();

        // We cannot unset name or domainName
        if(name === ""){
            toast.error("Field 'name' cannot be empty.")
            return;
        }

        if(domainName === ""){
            toast.error("Field 'domainName' cannot be empty.")
            return;
        }

        const data = {
            name: name,
            domain_name: domainName,
            load_balancing_strategy: loadBalancingStrategy,
            active: active,
            geo_routing_enabled: geoRoutingEnabled
        }

        updateDomain(authHeader, domain._id, data)
            .then(() => {
                toast.success("Domain has been updated.");
                setOpen(false);

                // Update domain list
                getDomains(authHeader, null)
                    .then(result => setDomainList(result))
                    .catch(() => {
                        navigate("/auth/sign-in");
                        toast.error("Your session has expired.");
                    })
            }).catch(error => {
            toast.error("Failed updating domain: " + error.message);
            })
    }

    return (
        <>
            <Button color="green" onClick={() => setOpen(true)}>
                <div className="flex items-center gap-3">
                    <HiOutlinePencilAlt className="text-xl"/>
                    Edit Domain
                </div>
            </Button>
            <Modal onClose={() => setOpen(false)} show={isOpen}>
                <Modal.Header className="border-b border-gray-200 !p-6">
                    <strong>Edit Domain</strong>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div>
                            <Label htmlFor="name">Name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="name"
                                    name="name"
                                    placeholder="Display Name"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="domainName">Domain Name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="domainName"
                                    name="domainName"
                                    placeholder="example.com"
                                    value={domainName}
                                    onChange={e => setDomainName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-9 pt-2">
                        <div>
                            <Label htmlFor="load-balancing-strategy">Load-Balancing Strategy</Label>
                            <div className="mt-1">
                                <Select id="load-balancing-strategy" required value={loadBalancingStrategy} onChange={(e) => setLoadBalancingStrategy(e.target.value)}>
                                    <option value="MERSENNE">Mersenne</option>
                                    <option value="ROUND_ROBIN">Round Robin</option>
                                    <option value="LOWEST_RESPONSE_TIME">Lowest Response Time</option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-9 pt-2">
                        <div className="pt-4 flex flex-row space-x-4 justify-center items-center">
                            <ToggleSwitch checked={active} label="Active" onChange={setActive}/>
                            <ToggleSwitch checked={geoRoutingEnabled} label="Geo-Routing"
                                          onChange={setGeoRoutingEnabled}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex space-x-3">
                        <Button color="blue" onClick={onDomainUpdate}>
                            Update
                        </Button>
                        <Button color="red" outline onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const DeleteDomainModal = ({ domain, setDomainList }) => {
    const authHeader = useAuthHeader();
    const navigate = useNavigate();

    const [isOpen, setOpen] = useState(false);

    const onDomainDelete = async (e: any) => {
        e.preventDefault();

        deleteDomain(authHeader, domain._id)
            .then(() => {
                toast.success("Domain has been deleted.");
                setOpen(false);

                // Update domain list
                getDomains(authHeader, null)
                    .then(result => setDomainList(result))
                    .catch(() => {
                        navigate("/auth/sign-in");
                        toast.error("Your session has expired.");
                    })
            }).catch(error => {
            toast.error("Failed deleting domain: " + error.message);
        })
    }

    return (
        <>
            <Button color="failure" onClick={() => setOpen(true)}>
                <div className="flex items-center">
                    <HiTrash className="text-lg"/>
                    Delete Domain
                </div>
            </Button>
            <Modal onClose={() => setOpen(false)} show={isOpen} size="md">
                <Modal.Header className="px-6 pt-6 pb-0">
                    <span className="sr-only">Delete Domain</span>
                </Modal.Header>
                <Modal.Body className="px-6 pt-0 pb-6">
                    <div className="flex flex-col items-center gap-y-6 text-center">
                        <HiOutlineExclamationCircle className="text-7xl text-red-500"/>
                        <p className="text-xl text-gray-500">
                            Are you sure you want to delete domain '{domain.domain_name}'?
                        </p>
                        <div className="flex items-center gap-x-3">
                            <Button color="failure" onClick={onDomainDelete}>
                                Yes, I'm sure
                            </Button>
                            <Button color="gray" onClick={() => setOpen(false)}>
                                No, cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Domains;
