import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthOutlet from '@auth-kit/react-router/AuthOutlet'

import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import Users from './pages/dashboard/Users'
import Domains from "./pages/dashboard/Domains";
import DomainRecords from "./pages/dashboard/domain/DomainRecords";

const App = () => {
    return (
      <Router>
          <Routes>
              <Route path="/auth/sign-in" element={<SignIn />}/>
              <Route path="/auth/sign-up" element={<SignUp />}/>
              <Route element={<AuthOutlet fallbackPath="/auth/sign-in" />}>
                  <Route path="/dashboard/users" element={<Users />} />
                  <Route path="/dashboard/domains" element={<Domains />} />
                  <Route path="/dashboard/domain/:domainId/records" element={<DomainRecords />} />

                  <Route path="/" element={<Navigate to="/dashboard/domains" />} />
                  <Route path="/dashboard" element={<Navigate to="/dashboard/domains" />} />
              </Route>
          </Routes>
      </Router>
    );
}

export default App;