import axios from "axios";

export async function createRecord(authHeader: string, domain_id: string, data: any) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/domain/${domain_id}/records`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': authHeader,
                }
            }
        );
    } catch (error) {
        throw new Error(error.response?.data?.detail || error.message);
    }
}

export async function updateRecord(authHeader: string, domain_id: string, record_id: string, data: any) {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/domain/${domain_id}/records/${record_id}`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': authHeader,
                }
            }
        );

        console.log(response)
    } catch (error: any) {
        throw new Error(error.response?.data?.detail || error.message);
    }
}

export async function deleteRecord(authHeader: string, domain_id: string, record_id: string) {
    try {
        await axios.delete(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/domain/${domain_id}/records/${record_id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': authHeader,
                }
            }
        );
    } catch (error: any) {
        throw new Error(error.response?.data?.detail || error.message)
    }
}

export function convertToContinentCode(continentName: string | null): string {
    if (continentName === null) {
        throw new Error("Continent Name cannot be null");
    }

    const continentNames: { [key: string]: string } = {
        'africa': 'AF',
        'antarctica': 'AN',
        'asia': 'AS',
        'europe': 'EU',
        'north america': 'NA',
        'oceania': 'OC',
        'south america': 'SA'
    };

    const normalizedName = continentName.toLowerCase();
    return (continentNames[normalizedName] || 'UNKNOWN').toUpperCase();
}