import React from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';

import { Footer } from 'flowbite-react';

const NavbarSidebarLayout = ({ children, isFooter = true }) => {
    return (
        <>
            <Navbar />
            <div className="flex h-screen items-start">
                <Sidebar/>
                <main className="relative h-full w-full overflow-y-auto bg-white pt-16">
                    <div className="h-full min-h-full">
                        { children }
                    </div>
                    {isFooter && <MainContentFooter/>}
                </main>
            </div>
        </>
    );
}

const MainContentFooter = () => {
    return (
        <>
            <div className="mx-4 mt-4">
                <Footer container>
                    <div
                        className="flex w-full justify-center items-center gap-y-6 lg:flex-row lg:justify-between lg:gap-y-0">
                        <Footer.LinkGroup className="flex w-full justify-around items-center">
                            <Footer.Link href="https://cygnalabs.com/en/terms-service/" className="mr-3 mb-3 lg:mb-0">Terms of Service</Footer.Link>
                            <Footer.Link href="https://cygnalabs.com/en/privacy-policy/" className="mr-3 mb-3 lg:mb-0">Privacy Policy</Footer.Link>
                            <Footer.Link href="https://cygnalabs.com/en/eula/" className="mr-3 mb-3 lg:mb-0">EULA</Footer.Link>
                            <Footer.Link href="https://cygnalabs.com/en/" className="mr-3 mb-3 lg:mb-0">Cygna Labs</Footer.Link>
                            <Footer.Link href="https://www.n3k.com/" className="mr-3 mb-3 lg:mb-0">n3k Informatik GmbH</Footer.Link>
                            <Footer.Link href="https://db-ip.com" className="mr-3 mb-3 lg:mb-0">IP Geolocation by DB-IP</Footer.Link>
                            <Footer.Link href="https://www.maxmind.com" className="mr-3 mb-3 lg:mb-0">IP Geolocation by MaxMind</Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                </Footer>
                <p className="my-8 text-center text-sm text-gray-500 dark:text-gray-300">
                    &copy; 2024 Cygna Labs. All Rights reserved.
                </p>
            </div>
        </>
    )
}

export default NavbarSidebarLayout;