import React from 'react';
import ReactDOM from 'react-dom/client';

import {Flowbite} from 'flowbite-react';

import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';
import {Toaster} from 'sonner';

import App from "./App.tsx"

import flowbiteTheme from './flowbite-theme.ts'

import './index.css';

const store = createStore({
    authName: '_auth',
    authType: 'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
});

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <Flowbite theme={{ theme: flowbiteTheme }}>
            <AuthProvider store={store}>
                <Toaster richColors position="bottom-right"/>
                <App/>
            </AuthProvider>
        </Flowbite>
    </React.StrictMode>
);