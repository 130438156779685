import axios from "axios";

import {UserCreate} from "./utilService";

export async function isCodeValid(code: string) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/invite/${code}`,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                }
            }
        )
        return response.status === 200;
    } catch {
        return false;
    }
}

export async function createUserWithCode (code: string, userData: UserCreate){
    try {
        await axios.post(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/invite/${code}`,
            userData,
            {
                headers: {
                    'Accept': 'application/json'
                }
            }
        )
    } catch (error) {
        throw new Error(error.response?.data?.detail || error.message)
    }
}

export async function getCode (authHeader: string, expirationTime: number) {
    try {
        return await axios.post(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/invite`,
            {
                expiration_time: expirationTime
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': authHeader,
                }
            }
        ).then(response => {
            return response.data.code;
        })
    } catch (error: any) {
        throw new Error(error.response?.data?.detail || error.message)
    }
}