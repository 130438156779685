import React from 'react';

import {Sidebar as SidebarFlowbite} from 'flowbite-react';
import {useEffect, useState} from 'react';
import {
    HiUsers,
    HiGlobe,
} from 'react-icons/hi';

const Sidebar = () => {
    const [currentPage, setCurrentPage] = useState('')

    useEffect(() => {
        const newPage = window.location.pathname;

        setCurrentPage(newPage);
    }, [setCurrentPage]);

    return (
        <SidebarFlowbite aria-label="Sidebar">
            <SidebarFlowbite.Items>
                <SidebarFlowbite.ItemGroup>
                    <SidebarFlowbite.Item href="/dashboard/domains" icon={HiGlobe}
                                          className={currentPage === "/dashboard/domains" ? "bg-gray-100" : ""}>Dashboard
                    </SidebarFlowbite.Item>
                    <SidebarFlowbite.Item href="/dashboard/users" icon={HiUsers}
                                          className={currentPage === "/dashboard/users" ? "bg-gray-100" : ""}>Users
                    </SidebarFlowbite.Item>
                </SidebarFlowbite.ItemGroup>
            </SidebarFlowbite.Items>

        </SidebarFlowbite>
    );
}

export default Sidebar;