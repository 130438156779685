import React from 'react';
import {useState, useEffect} from 'react';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import {useNavigate} from "react-router-dom";

import {toast} from "sonner";

import NavbarSidebarLayout from '../../layouts/NavbarSidebarLayout';
import {
    Breadcrumb,
    Button,
    Modal,
    TextInput,
    Label,
    Table,
    ToggleSwitch,
    Spinner,
    Kbd,
    Select,
    Clipboard
} from 'flowbite-react';
import {
    HiHome,
    HiMail,
    HiPlus,
    HiOutlinePencilAlt,
    HiTrash,
    HiOutlineExclamationCircle
} from 'react-icons/hi';

import { getUsers, createUser, updateUser, deleteUser } from '../../services/userService';
import { isPasswordValid, UserUpdate } from '../../services/utilService';
import { getCode } from '../../services/inviteService';

const Users = () => {
    const authHeader = useAuthHeader();
    const navigate = useNavigate();

    const [usersSearchQuery, setUsersSearchQuery] = useState('');
    const [usersList, _setUsersList] = useState([]);

    const setUsersList = (data: any) => {
        const sortedData = data.sort((a: any, b: any) => {
            const lastNameComparison = a.last_name.localeCompare(b.last_name);

            if (lastNameComparison === 0) {
                return a.first_name.localeCompare(b.first_name);
            }
            return lastNameComparison;
        });
        _setUsersList(sortedData);
    }

    const intervalTime = 10000;

    useEffect(() => {
        const fetchUsers = () => {
            getUsers(authHeader, null)
                .then(result => setUsersList(result))
                .catch(() => {
                navigate("/auth/sign-in");
                toast.error("Your session has expired.")
            });
        }

        fetchUsers();

        const intervalId = setInterval(fetchUsers, intervalTime);

        return () => clearInterval(intervalId)
    }, [authHeader, intervalTime])

    const onUserSearchChange = async (e: any) => {
        setUsersSearchQuery(e.target.value);
        await getUsers(authHeader, e.target.value).then(result => setUsersList(result));
    }


    return (
        <NavbarSidebarLayout isFooter={false}>
            <div className="block items-center justify-between border-b border-gray-200 bg-white p-4">
                <div className="mb-1 w-full">
                    {/* Breadcrumbs */}
                    <div className="mb-4">
                        <Breadcrumb className="mb-4">
                            <Breadcrumb.Item href="/dashboard">
                                <div className="flex items-center gap-x-3">
                                    <HiHome className="text-xl"/>
                                    <span>Dashboard</span>
                                </div>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Users</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1 className="text-xl font-semibold text-gray-900">All users</h1>
                    </div>

                    {/* Controls */}
                    <div className="flex justify-between items-center">
                        <form className="pr-3"  onSubmit={() => undefined}>
                            <div className="relative mt-1 lg:w-64 xl:w-96">
                                <TextInput id="users-search" name="users-search" placeholder="Search for users"
                                           value={usersSearchQuery} onChange={onUserSearchChange} onEmptied={onUserSearchChange}/>
                            </div>
                        </form>
                        <div className="flex space-x-4 items-center">
                            <AddUserModal setUsersList={setUsersList}/>
                            <CreateInviteCodeModal/>
                        </div>
                    </div>

                </div>
            </div>

            <div className="flex flex-col">
                <div className="overflow-x-auto">
                    <div className="inline-block min-w-full align-middle">
                        <div className="overflow-hidden shadow">
                            <Table className="min-w-full divide-y divide-gray-200">
                                <Table.Head className="bg-gray-100">
                                    <Table.HeadCell>Name</Table.HeadCell>
                                    <Table.HeadCell>Username</Table.HeadCell>
                                    <Table.HeadCell>Position</Table.HeadCell>
                                    <Table.HeadCell>Country</Table.HeadCell>
                                    <Table.HeadCell>Privileges</Table.HeadCell>
                                    <Table.HeadCell>Actions</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y divide-gray-200 bg-white">

                                    {
                                        usersList.length !== 0 && usersList.map(user => (
                                            <UserInRow user={user} key={user.id}
                                                       setUsersList={setUsersList}/>
                                        ))
                                    }
                                </Table.Body>
                            </Table>
                            {
                                usersList.length === 0 &&
                                <>
                                    <div className="flex w-full h-full items-center justify-center pt-20">
                                        <Spinner size="xl"/>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </NavbarSidebarLayout>
    );
}

const UserInRow = ({user, setUsersList}) => {
    return (
        <>
            <Table.Row className="hover:bg-gray-100">
                <Table.Cell>
                    <div className="text-sm font-normal text-gray-500">
                        <div className="text-base font-semibold text-gray-900">
                            {user.first_name + " " + user.last_name}
                        </div>
                        <div className="text-sm font-normal text-gray-500">
                            {user.email}
                        </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    {user.username}
                </Table.Cell>
                <Table.Cell>
                    {user.meta ? user.meta.position : ""}
                </Table.Cell>
                <Table.Cell>
                    {user.meta ? user.meta.country : ""}
                </Table.Cell>
                <Table.Cell className="mr-6">
                    {user.administrator ? "Administrator" : "User"}
                </Table.Cell>
                <Table.Cell>
                    <div className="flex items-center justify-start space-x-5 whitespace-nowrap">
                        <EditUserModal user={user} setUsersList={setUsersList}/>
                        <DeleteUserModal user={user} setUsersList={setUsersList}/>
                    </div>
                </Table.Cell>
            </Table.Row>
        </>
    )
}


const AddUserModal = ({setUsersList}) => {
    const authHeader = useAuthHeader();
    const authUser = useAuthUser();
    const navigate = useNavigate();

    const [isOpen, setOpen] = useState(false);

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [userName, setUserName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordRepeat, setPasswordRepeat] = useState("")

    const onUserCreate = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        const passwordValid = isPasswordValid(password, passwordRepeat);
        if( !passwordValid ){
            toast.error("Password does not match the criteria.")
            return;
        }

        if (!firstName.length || !lastName.length || !userName.length || !email.length) {
            toast.error("Creating a user requires Name, Email and Password")
            return;
        }

        const data = {
            first_name: firstName,
            last_name: lastName,
            email: email,

            username: userName,
            password: password,
        };


        createUser(authHeader, data)
            .then(() => {
                toast.success("User has been created")
                setOpen(false)
                getUsers(authHeader, null)
                    .then(result => setUsersList(result))
                    .catch(() => {
                        navigate("/auth/sign-in");
                        toast.error("Your session has expired.")
                    })
            }).catch(error => {
                toast.error("Failed creating user: " + error.message)
            })
    }

    return (
        <>
            <Button color="blue" onClick={() => setOpen(true)} disabled={ !authUser["administrator"] }>
                <div className="flex items-center gap-3">
                    <HiPlus className="text-xl"/>
                    Add User
                </div>
            </Button>
            <Modal onClose={() => setOpen(false)} show={isOpen}>
                <Modal.Header className="border-b border-gray-200 !p-6">
                    <strong>Add new user</strong>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div>
                            <Label htmlFor="firstName">First name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="firstName"
                                    name="firstName"
                                    placeholder="Bonnie"
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="lastName">Last name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Green"
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="username">Username</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="username"
                                    name="username"
                                    placeholder="@cygnauser"
                                    value={userName}
                                    onChange={e => setUserName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="email">Email</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="email"
                                    name="email"
                                    placeholder="example@company.com"
                                    type="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 border-t border-gray-100 mt-9 pt-2">
                        <div>
                            <Label htmlFor="passwordNew">New password</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="passwordNew"
                                    name="passwordNew"
                                    placeholder="••••••••"
                                    className="tracking-widest"
                                    type="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="passwordRepeat">Repeat password</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="passwordRepeat"
                                    name="passwordRepeat"
                                    placeholder="••••••••"
                                    className="tracking-widest"
                                    type="password"
                                    value={passwordRepeat}
                                    onChange={e => setPasswordRepeat(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex space-x-3">
                        <Button color="blue" onClick={onUserCreate}>
                            Save all
                        </Button>
                        <Button color="red" outline onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const CreateInviteCodeModal = () => {
    const authHeader = useAuthHeader()
    const authUser = useAuthUser()

    const [isOpen, setOpen] = useState(false)
    const [expirationTime, setExpirationTime] = useState(60 * 60 * 24)
    const [code, setCode] = useState("000000")

    useEffect(() => {
        if(!authUser["administrator"]) return;

        getCode(authHeader, expirationTime).then((result: any) => {
            setCode(result);
        }).catch((error: any) => {
            return toast.error("Failed getting invite code: " + error.message);
        });
    }, [authHeader, expirationTime, authUser]);

    return (
        <>
            <Button color="gray" onClick={() => setOpen(true)} disabled={ !authUser["administrator"] }>
                <div className="flex items-center gap-3">
                    <HiMail className="text-xl"/>
                    Invite Code
                </div>
            </Button>
            <Modal onClose={() => setOpen(false)} show={isOpen}>
                <Modal.Header className="border-b border-gray-200 !p-6">
                    <strong>Invite Code</strong>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex justify-around -space-x-60 items-center">
                        <Kbd className="py-2 px-4 text-xl">{code.charAt(0)}</Kbd>
                        <Kbd className="py-2 px-4 text-xl">{code.charAt(1)}</Kbd>
                        <Kbd className="py-2 px-4 text-xl">{code.charAt(2)}</Kbd>
                        <strong>-</strong>
                        <Kbd className="py-2 px-4 text-xl">{code.charAt(3)}</Kbd>
                        <Kbd className="py-2 px-4 text-xl">{code.charAt(4)}</Kbd>
                        <Kbd className="py-2 px-4 text-xl">{code.charAt(5)}</Kbd>
                    </div>

                    <div className="flex flex-col justify-center items-center space-y-5 pt-5">
                        <div className="flex flex-row items-center justify-center">
                            <Clipboard.WithIconText valueToCopy={code} label="Copy" />
                        </div>


                        <div>
                            <Label className="leading-relaxed" htmlFor="expiration-time">Expiration time</Label>
                            <Select id="expiration-time" onChange={e => {
                                setExpirationTime(Number(e.target.value));
                                getCode(authHeader, expirationTime).then(result => setCode(result)).catch(() => toast.error("Failed getting invite code"));
                            }}>
                                <option value={60 * 60 * 24}>1 Day</option>
                                <option value={60 * 60 * 24 * 7}>1 Week</option>
                                <option value={60 * 60 * 24 * 7 * 31}>1 Month</option>
                            </Select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button outline color="red" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


const EditUserModal = ({user, setUsersList}) => {
    const authHeader = useAuthHeader();
    const authUser = useAuthUser();
    const navigate = useNavigate();

    const [isOpen, setOpen] = useState(false);
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);
    const [userName, setUserName] = useState(user.username);
    const [email, setEmail] = useState(user.email);
    const [position, setPosition] = useState(user.meta ? user.meta.position : "");
    const [country, setCountry] = useState(user.meta ? user.meta.country : "");
    const [administrator, setAdministrator] = useState(user.administrator);
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');


    const onUserUpdate = async (e: any) => {
        e.preventDefault();

        const data: UserUpdate = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            username: userName,
            administrator: administrator,
            meta: {
                position: position,
                country: country
            }
        };


        // Only set password if it isn't empty
        if (password !== "") {
            const passwordValid = isPasswordValid(password, passwordRepeat);
            if ( !passwordValid ){
                toast.error("Password does not match the criteria.")
                return;
            }

            data["password"] = password;
        }
        updateUser(authHeader, user._id, data)
            .then(() => {
                toast.success("User has been updated")
                setOpen(false)
                getUsers(authHeader, null)
                    .then(result => setUsersList(result))
                    .catch(() => {
                        navigate("/auth/sign-in");
                        toast.error("Your session has expired.")
                    })
            }).catch(error => {
                toast.error("Failed updating user: " + error.message)
            })

        // Close Modal and re-render Parent Components
        setOpen(false)
        getUsers(authHeader, null).then((result: any) => setUsersList(result));
    }


    return (
        <>
            <Button color="green" onClick={() => setOpen(true)} disabled={ !authUser["administrator"] && user._id !== authUser["_id"] }>
                <div className="flex items-center">
                    <HiOutlinePencilAlt className="text-lg"/>
                    Edit user
                </div>
            </Button>
            <Modal onClose={() => setOpen(false)} show={isOpen}>
                <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
                    <strong>Edit user</strong>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div>
                            <Label htmlFor="firstName">First name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="firstName"
                                    name="firstName"
                                    placeholder="Bonnie"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="lastName">Last name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Green"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}

                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="username">Username</Label>
                            <div className="mt-1">
                                <TextInput
                                    disabled={authUser["_id"] === user._id}
                                    id="username"
                                    name="username"
                                    type="text"
                                    value={userName}
                                    onChange={e => setUserName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="email">Email</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="email"
                                    name="email"
                                    placeholder="example@company.com"
                                    type="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="position">Position</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="position"
                                    name="position"
                                    placeholder="Network Administrator"
                                    value={position}
                                    onChange={e => setPosition(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="country">Country</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="country"
                                    name="country"
                                    placeholder="United States"
                                    value={country}
                                    onChange={e => setCountry(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="flex space-x-2 items-center">
                            <ToggleSwitch checked={administrator} onChange={setAdministrator} disabled={ !authUser["administrator"]} label="Administrator"/>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 border-t border-gray-100 mt-9 pt-2">
                        <div>
                            <Label htmlFor="passwordNew">New password</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="passwordNew"
                                    name="passwordNew"
                                    placeholder="••••••••"
                                    className="tracking-widest"
                                    type="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="passwordRepeat">Repeat password</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="passwordRepeat"
                                    name="passwordRepeat"
                                    placeholder="••••••••"
                                    className="tracking-widest"
                                    type="password"
                                    value={passwordRepeat}
                                    onChange={e => setPasswordRepeat(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex space-x-3">
                        <Button color="blue" onClick={onUserUpdate}>
                            Save all
                        </Button>
                        <Button color="red" outline onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    );
};

const DeleteUserModal = ({user, setUsersList}) => {
    const authHeader = useAuthHeader();
    const authUser = useAuthUser();
    const navigate = useNavigate();

    const [isOpen, setOpen] = useState(false);

    const onUserDelete = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        deleteUser(authHeader, user._id)
            .then(() => {
                toast.success("User has been deleted")
                setOpen(false)
                getUsers(authHeader, null)
                    .then(result => setUsersList(result))
                    .catch(() => {
                        navigate("/auth/sign-in");
                        toast.error("Your session has expired.")
                    })
            }).catch(error => {
            toast.error("Failed deleting user: " + error.message)
        })
    }

    return (
        <>
            <Button color="failure" onClick={() => setOpen(true)} disabled={ !authUser["administrator"] }>
                <div className="flex items-center">
                    <HiTrash className="text-lg"/>
                    Delete user
                </div>
            </Button>
            <Modal onClose={() => setOpen(false)} show={isOpen} size="md">
                <Modal.Header className="px-6 pt-6 pb-0">
                    <span className="sr-only">Delete user</span>
                </Modal.Header>
                <Modal.Body className="px-6 pt-0 pb-6">
                    <div className="flex flex-col items-center gap-y-6 text-center">
                        <HiOutlineExclamationCircle className="text-7xl text-red-500"/>
                        <p className="text-xl text-gray-500">
                            Are you sure you want to delete user '{user.username}'?
                        </p>
                        <div className="flex items-center gap-x-3">
                            <Button color="failure" onClick={onUserDelete}>
                                Yes, I'm sure
                            </Button>
                            <Button color="gray" onClick={() => setOpen(false)}>
                                No, cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};


export default Users;