import axios from "axios";

export async function getDomains(authHeader: string, queryString: string = null): Promise<any[]> {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/domain/`,
            {
                params: {
                    query: queryString,
                },
                headers: {
                    'Accept': 'application/json',
                    'Authorization': authHeader,
                }
            }
        );

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.detail || error.message);
    }
}

export async function getDomain(authHeader: string, domainId: string = null): Promise<any[]> {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/domain/${domainId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': authHeader,
                }
            }
        );

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.detail || error.message);
    }
}

export async function createDomain(authHeader: string, data: any) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/domain/`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': authHeader,
                }
            }
        );
    } catch (error) {
        throw new Error(error.response?.data?.detail || error.message);
    }
}

export async function updateDomain(authHeader: string, domain_id: string, data: any) {
    try {
        console.log(`Making POST request to: ${process.env.REACT_APP_CONTROLLER_URL}/api/v1/domain`);
        const response = await axios.put(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/domain/${domain_id}`,
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': authHeader,
                }
            }
        );

        console.log(response)
    } catch (error: any) {
        throw new Error(error.response?.data?.detail || error.message);
    }
}

export async function deleteDomain(authHeader: string, domain_id: string) {
    try {
        await axios.delete(
            `${process.env.REACT_APP_CONTROLLER_URL}/api/v1/domain/${domain_id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': authHeader,
                }
            }
        );
    } catch (error: any) {
        throw new Error(error.response?.data?.detail || error.message)
    }
}