import React from 'react';
import {useEffect, useState} from 'react'
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "sonner";

import {
    Badge,
    Button,
    Dropdown,
    Label,
    Modal,
    Select,
    Spinner,
    Table,
    TextInput,
    ToggleSwitch
} from "flowbite-react";
import {
    HiBan,
    HiCheck,
    HiGlobe,
    HiPlus,
    HiOutlinePencilAlt, HiTrash, HiOutlineExclamationCircle,
} from "react-icons/hi";

import {HiArrowLeft} from "react-icons/hi2";
import {FaCircle, FaThumbsDown} from "react-icons/fa6";
import {FaLock, FaThumbsUp} from "react-icons/fa";

import {getDomain} from '../../../services/domainService';
import {createRecord, updateRecord, deleteRecord, convertToContinentCode} from "../../../services/recordService";

import NavbarSidebarLayout from "../../../layouts/NavbarSidebarLayout";

const DomainRecords = () => {
    const {domainId} = useParams();

    const authHeader = useAuthHeader();
    const navigate = useNavigate();

    const [domain, _setDomain] = useState({});
    const [recordList, _setRecordList] = useState([]);

    const [isCreateAOpen, setCreateAOpen] = useState(false);
    const [isCreateAAAAOpen, setCreateAAAAOpen] = useState(false);
    const [isCreateCNAMEOpen, setCreateCNAMEOpen] = useState(false);

    const toggleAOpen = () => setCreateAOpen(!isCreateAOpen);
    const toggleAAAAOpen = () => setCreateAAAAOpen(!isCreateAAAAOpen);
    const toggleCNAMEOpen = () => setCreateCNAMEOpen(!isCreateCNAMEOpen);


    const setRecordList = (data) => {
        _setRecordList(data.sort((a, b) => a.name.localeCompare(b.name)))
    }

    const setDomain = (data) => {
        _setDomain(data);
        setRecordList(data.records)
    }

    const intervalTime = 10000;

    useEffect(() => {
        const updateRecords = () => {
            getDomain(authHeader, domainId).then(_domain => {
                setDomain(_domain);
                setRecordList(_domain["records"]);
            });
        }

        updateRecords();

        const intervalId = setInterval(updateRecords, intervalTime);

        return () => clearInterval(intervalId)
    }, [authHeader, intervalTime, domainId])

    return (
        <NavbarSidebarLayout isFooter={false}>
            <div className="block items-center justify-between border-b border-gray-200 bg-white p-4">
                <div className="mb-1 w-full">
                    {/* Breadcrumbs */}
                    <div className="mb-4">
                        <Button color="gray" className="mb-4" onClick={() => navigate("/dashboard/domains")}>
                            <div className="flex items-center space-x-1">
                                <HiArrowLeft/>
                                <span>Go back</span>
                            </div>
                        </Button>
                        <h1 className="text-xl font-semibold text-gray-900">All Records for Domain '{domainId}'</h1>
                    </div>

                    {/* Controls */}
                    <div className="flex justify-between items-center">
                        <form className="pr-3">
                            <div className="relative mt-1 lg:w-64 xl:w-96">
                            </div>
                        </form>
                        <div className="flex space-x-4 items-center pr-4">
                            <Dropdown label="Add Record" placement="bottom-end" arrowIcon={false}
                                      disabled={domain["records"] && domain["records"].length === 1 && domain["records"][0].type === "CNAME"}
                            >
                                <Dropdown.Item onClick={toggleAOpen}>A Record</Dropdown.Item>
                                <Dropdown.Item onClick={toggleAAAAOpen}>AAAA Record</Dropdown.Item>
                                { recordList.length === 0 && <Dropdown.Item onClick={toggleCNAMEOpen}>CNAME Record</Dropdown.Item> }
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col">
                <div className="overflow-x-auto">
                    <div className="inline-block min-w-full align-middle">
                        <div className="overflow-hidden shadow w-full">
                            <Table className="min-w-full divide-y divide-gray-200">
                                <Table.Head className="bg-gray-100">
                                    <Table.HeadCell>Name</Table.HeadCell>
                                    <Table.HeadCell>Type</Table.HeadCell>
                                    <Table.HeadCell>TTL</Table.HeadCell>
                                    <Table.HeadCell>Active</Table.HeadCell>
                                    <Table.HeadCell>State</Table.HeadCell>
                                    <Table.HeadCell>Response Time</Table.HeadCell>
                                    <Table.HeadCell>Actions</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y divide-gray-200 bg-white">
                                    {
                                        recordList.length !== 0 && recordList.map(record => (
                                            <RecordInRow domainId={domainId} record={record} key={record.id}
                                                         setDomain={setDomain}/>
                                        ))
                                    }
                                </Table.Body>
                            </Table>
                            {
                                recordList.length === 0 &&
                                <>
                                    <div className="flex w-full h-full items-center justify-center pt-20">
                                        <Spinner size="xl"/>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {isCreateAOpen === true &&
                <AddARecordModal domainId={domainId} setDomain={setDomain} isOpen={isCreateAOpen} setOpen={setCreateAOpen}/>}
            {isCreateAAAAOpen === true &&
                <AddAAAARecordModal domainId={domainId} setDomain={setDomain} isOpen={isCreateAAAAOpen} setOpen={setCreateAAAAOpen}/>}
            {isCreateCNAMEOpen === true &&
                <AddCNAMERecordModal domainId={domainId} setDomain={setDomain} isOpen={isCreateCNAMEOpen} setOpen={setCreateCNAMEOpen}/>}
        </NavbarSidebarLayout>
    );
}

const RecordInRow = ({domainId, record, setDomain}) => {
    const getResponseTimeBadge = (responseMillis: number) => {
        let color = "red";
        let icon = FaThumbsDown;

        console.log(responseMillis === -1)

        if (responseMillis < 1000) {
            color = "green";
            icon = FaThumbsUp;
        }
        if (responseMillis == -1) {
            color = "gray"
            icon = FaLock;
            responseMillis = 0.0
        }
        
	return (
            <div className="w-min">
                <Badge icon={icon} color={color}>{responseMillis}&nbsp;ms&nbsp;</Badge>
            </div>
        );
    }

    const getStateBadge = (state: string) => {
        let color = "gray"

        if (state === "UP") {
            color = "green";
        } else if (state === "DOWN") {
            color = "red"
        }

        return (
            <div className="w-min">
                <Badge icon={FaCircle} color={color}>{state}&nbsp;</Badge>
            </div>
        );
    }

    return (
        <>
            <Table.Row className="hover:bg-gray-100">
                <Table.Cell>
                    <div className="text-sm font-normal text-gray-500">
                        <div className="text-base font-semibold text-gray-900">
                            <span>{record.name}</span>
                        </div>
                        <div className="flex items-center space-x-1 text-sm font-normal text-gray-500">
                            <HiGlobe/>
                            <span>{record.type === "CNAME" ? record.alias : record.address}</span>
                        </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <span>{record.type}</span>
                </Table.Cell>
                <Table.Cell>
                    <span>{record.ttl}</span>
                </Table.Cell>
                <Table.Cell>
                    {
                        record.active ?
                            <div className="w-min">
                                <Badge icon={HiCheck} color="green">Active&nbsp;</Badge>
                            </div>
                            :
                            <div className="w-min">
                                <Badge icon={HiBan} color="red">Inactive&nbsp;</Badge>
                            </div>
                    }
                </Table.Cell>
                <Table.Cell>
                    {record.type === "CNAME" ? getStateBadge("UNKNOWN") : getStateBadge(record.state)}
                </Table.Cell>
                <Table.Cell>
                    {record.type === "CNAME" ? getResponseTimeBadge(-1.0) : getResponseTimeBadge(record.response_time.toFixed(2))}
                </Table.Cell>
                <Table.Cell>
                    <div className="flex items-center justify-start space-x-5 whitespace-nowrap">
                        <DeleteRecordModal domainId={domainId} record={record} setDomain={setDomain} />
                        { record.type === "A" && <EditARecordModal domainId={domainId} record={record} setDomain={setDomain}/> }
                        { record.type === "AAAA" && <EditAAAARecordModal domainId={domainId} record={record} setDomain={setDomain}/> }
                        { record.type === "CNAME" && <EditCNAMERecordModal domainId={domainId} record={record} setDomain={setDomain}/> }
                    </div>
                </Table.Cell>
            </Table.Row>
        </>
    )
}

const AddARecordModal = ({domainId, setDomain, isOpen, setOpen}) => {
    const authHeader = useAuthHeader();
    const navigate = useNavigate();

    const [name, setName] = useState('')
    const [address, setAddress] = useState('')

    const [ttl, setTTL] = useState(60);
    const [active, setActive] = useState(false);

    const [continent, setContinent] = useState("");
    const [healthCheckStrategy, setHealthCheckStrategy] = useState('PING')

    const onRecordCreate = async (e: any) => {
        e.preventDefault();

        const data = {
            name: name,
            type: "A",
            ttl: ttl,
            active: active,
            address: address,
            health_check_strategy: healthCheckStrategy,
        }

        // Omit location if continent is global
        if(continent){
            data["location"] = {}
            data["location"]["continent"] = continent
            data["location"]["continent_code"] = convertToContinentCode(continent);
        }

        createRecord(authHeader, domainId, data).then(() => {
            toast.success("Record has been created.");
            setOpen(false);

            // Update domain list
            getDomain(authHeader, domainId)
                .then(result => setDomain(result))
                .catch(() => {
                    navigate("/auth/sign-in");
                    toast.error("Your session has expired.");
                })
        }).catch(error => {
            toast.error("Failed creating record: " + error.message);
        })
    }

    return (
        <>
            <Modal onClose={() => setOpen(false)} show={isOpen}>
                <Modal.Header className="border-b border-gray-200 !p-6">
                    <strong>Create new A Record</strong>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div>
                            <Label htmlFor="name">Name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="name"
                                    name="name"
                                    placeholder="Display Name"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="ipAddress">IPv4 Address</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="ipAddress"
                                    name="ipAddress"
                                    placeholder="8.8.8.8"
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-4 pt-2">
                        <div>
                            <Label htmlFor="load-balancing-strategy">Health-Check Strategy</Label>
                            <div className="mt-1">
                                <Select id="load-balancing-strategy" required value={healthCheckStrategy}
                                        onChange={(e) => setHealthCheckStrategy(e.target.value)}>
                                    <option value="PING">Ping</option>
                                    <option value="HTTP">HTTP</option>
                                </Select>
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="continent">Service Region</Label>
                            <div className="mt-1">
                                <Select id="load-balancing-strategy" required value={continent}
                                        onChange={(e) => setContinent(e.target.value)}>
                                    <option value="">Global</option>
                                    <option value="EUROPE">Europe</option>
                                    <option value="ASIA">Asia</option>
                                    <option value="AFRICA">Africa</option>
                                    <option value="NORTH_AMERICA">North America</option>
                                    <option value="SOUTH_AMERICA">South America</option>
                                    <option value="OCEANIA">Oceania</option>
                                    <option value="ANTARCTICA">Antarctica</option>

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-4 pt-2">
                        <div>
                            <Label htmlFor="ttl">TTL</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="ttl"
                                    name="ttl"
                                    placeholder="60"
                                    value={ttl}
                                    onChange={e => setTTL(parseInt(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className="pt-7 flex items-center justify-center">
                            <ToggleSwitch checked={active} label="Active" onChange={setActive}/>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex space-x-3">
                        <Button color="blue" onClick={onRecordCreate}>
                            Create
                        </Button>
                        <Button color="red" outline onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const EditARecordModal = ({domainId, record, setDomain}) => {
    const authHeader = useAuthHeader();
    const navigate = useNavigate();

    const [isOpen, setOpen] = useState(false);

    const [name, setName] = useState(record.name)
    const [address, setAddress] = useState(record.address)

    const [ttl, setTTL] = useState(record.ttl);
    const [active, setActive] = useState(record.active);

    const [continent, setContinent] = useState(record.location ? record.location.continent.toUpperCase() : "");
    const [healthCheckStrategy, setHealthCheckStrategy] = useState(record.health_check_strategy)

    const onRecordUpdate = async (e: any) => {
        e.preventDefault();

        const data = {
            name: name,
            ttl: ttl,
            active: active,
            health_check_strategy: healthCheckStrategy,
        }

        // Omit location if continent is global
        if(continent){
            data["location"] = {}
            data["location"]["continent"] = continent
            data["location"]["continent_code"] = convertToContinentCode(continent);
        }

        updateRecord(authHeader, domainId, record._id, data).then(() => {
            toast.success("Record has been updated.");
            setOpen(false);

            // Update domain list
            getDomain(authHeader, domainId)
                .then(result => setDomain(result))
                .catch(() => {
                    navigate("/auth/sign-in");
                    toast.error("Your session has expired.");
                })
        }).catch(error => {
            toast.error("Failed updating record: " + error.message);
        })
    }

    return (
        <>
            <Button color="green" onClick={() => setOpen(true)}>
                <div className="flex items-center">
                    <HiOutlinePencilAlt className="text-lg"/>
                    Edit Record
                </div>
            </Button>
            <Modal onClose={() => setOpen(false)} show={isOpen}>
                <Modal.Header className="border-b border-gray-200 !p-6">
                    <strong>Edit A Record</strong>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div>
                            <Label htmlFor="name">Name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="name"
                                    name="name"
                                    placeholder="Display Name"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="ipAddress">IPv4 Address</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="ipAddress"
                                    name="ipAddress"
                                    placeholder="8.8.8.8"
                                    value={address}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-4 pt-2">
                        <div>
                            <Label htmlFor="load-balancing-strategy">Health-Check Strategy</Label>
                            <div className="mt-1">
                                <Select id="load-balancing-strategy" required value={healthCheckStrategy}
                                        onChange={(e) => setHealthCheckStrategy(e.target.value)}>
                                    <option value="PING">Ping</option>
                                    <option value="HTTP">HTTP</option>
                                </Select>
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="continent">Service Region</Label>
                            <div className="mt-1">
                                <Select id="load-balancing-strategy" required value={continent}
                                        onChange={(e) => setContinent(e.target.value)}>
                                    <option value="">Global</option>
                                    <option value="EUROPE">Europe</option>
                                    <option value="ASIA">Asia</option>
                                    <option value="AFRICA">Africa</option>
                                    <option value="NORTH_AMERICA">North America</option>
                                    <option value="SOUTH_AMERICA">South America</option>
                                    <option value="OCEANIA">Oceania</option>
                                    <option value="ANTARCTICA">Antarctica</option>

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-4 pt-2">
                        <div>
                            <Label htmlFor="ttl">TTL</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="ttl"
                                    name="ttl"
                                    placeholder="60"
                                    value={ttl}
                                    onChange={e => setTTL(parseInt(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className="pt-7 flex items-center justify-center">
                            <ToggleSwitch checked={active} label="Active" onChange={setActive}/>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex space-x-3">
                        <Button color="blue" onClick={onRecordUpdate}>
                            Update
                        </Button>
                        <Button color="red" outline onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const AddAAAARecordModal = ({domainId, setDomain, isOpen, setOpen}) => {
    const authHeader = useAuthHeader();
    const navigate = useNavigate();

    const [name, setName] = useState('')
    const [address, setAddress] = useState('')

    const [ttl, setTTL] = useState(60);
    const [active, setActive] = useState(false);

    const [continent, setContinent] = useState("");
    const [healthCheckStrategy, setHealthCheckStrategy] = useState('PING')

    const onRecordCreate = async (e: any) => {
        e.preventDefault();

        const data = {
            name: name,
            type: "AAAA",
            ttl: ttl,
            active: active,
            address: address,
            health_check_strategy: healthCheckStrategy,
        }

        // Omit location if continent is global
        if(continent){
            data["location"] = {}
            data["location"]["continent"] = continent
            data["location"]["continent_code"] = convertToContinentCode(continent);
        }

        createRecord(authHeader, domainId, data).then(() => {
            toast.success("Record has been created.");
            setOpen(false);

            // Update domain list
            getDomain(authHeader, domainId)
                .then(result => setDomain(result))
                .catch(() => {
                    navigate("/auth/sign-in");
                    toast.error("Your session has expired.");
                })
        }).catch(error => {
            toast.error("Failed creating record: " + error.message);
        })
    }

    return (
        <>
            <Modal onClose={() => setOpen(false)} show={isOpen}>
                <Modal.Header className="border-b border-gray-200 !p-6">
                    <strong>Create new AAAA Record</strong>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div>
                            <Label htmlFor="name">Name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="name"
                                    name="name"
                                    placeholder="Display Name"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="ipAddress">IPv6 Address</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="ipAddress"
                                    name="ipAddress"
                                    placeholder="ec0:0ba9:80cd:affe"
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-4 pt-2">
                        <div>
                            <Label htmlFor="load-balancing-strategy">Health-Check Strategy</Label>
                            <div className="mt-1">
                                <Select id="load-balancing-strategy" required value={healthCheckStrategy}
                                        onChange={(e) => setHealthCheckStrategy(e.target.value)}>
                                    <option value="PING">Ping</option>
                                    <option value="HTTP">HTTP</option>
                                </Select>
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="continent">Service Region</Label>
                            <div className="mt-1">
                                <Select id="load-balancing-strategy" required value={continent}
                                        onChange={(e) => setContinent(e.target.value)}>
                                    <option value="">Global</option>
                                    <option value="EUROPE">Europe</option>
                                    <option value="ASIA">Asia</option>
                                    <option value="AFRICA">Africa</option>
                                    <option value="NORTH_AMERICA">North America</option>
                                    <option value="SOUTH_AMERICA">South America</option>
                                    <option value="OCEANIA">Oceania</option>
                                    <option value="ANTARCTICA">Antarctica</option>

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-4 pt-2">
                        <div>
                            <Label htmlFor="ttl">TTL</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="ttl"
                                    name="ttl"
                                    placeholder="60"
                                    value={ttl}
                                    onChange={e => setTTL(parseInt(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className="pt-7 flex items-center justify-center">
                            <ToggleSwitch checked={active} label="Active" onChange={setActive}/>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex space-x-3">
                        <Button color="blue" onClick={onRecordCreate}>
                            Create
                        </Button>
                        <Button color="red" outline onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const EditAAAARecordModal = ({domainId, record, setDomain}) => {
    const authHeader = useAuthHeader();
    const navigate = useNavigate();

    const [isOpen, setOpen] = useState(false);

    const [name, setName] = useState(record.name)
    const [address, setAddress] = useState(record.address)

    const [ttl, setTTL] = useState(record.ttl);
    const [active, setActive] = useState(record.active);

    const [continent, setContinent] = useState(record.location ? record.location.continent.toUpperCase() : "GLOBAL");
    const [healthCheckStrategy, setHealthCheckStrategy] = useState(record.health_check_strategy)

    const onRecordUpdate = async (e: any) => {
        e.preventDefault();

        const data = {
            name: name,
            ttl: ttl,
            active: active,
            health_check_strategy: healthCheckStrategy,
        }

        // Omit location if continent is global
        if(continent){
            data["location"] = {}
            data["location"]["continent"] = continent
            data["location"]["continent_code"] = convertToContinentCode(continent);
        }

        updateRecord(authHeader, domainId, record._id, data).then(() => {
            toast.success("Record has been updated.");
            setOpen(false);

            // Update domain list
            getDomain(authHeader, domainId)
                .then(result => setDomain(result))
                .catch(() => {
                    navigate("/auth/sign-in");
                    toast.error("Your session has expired.");
                })
        }).catch(error => {
            toast.error("Failed updating record: " + error.message);
        })
    }

    return (
        <>
            <Button color="green" onClick={() => setOpen(true)}>
                <div className="flex items-center">
                    <HiOutlinePencilAlt className="text-lg"/>
                    Edit Record
                </div>
            </Button>
            <Modal onClose={() => setOpen(false)} show={isOpen}>
                <Modal.Header className="border-b border-gray-200 !p-6">
                    <strong>Edit AAAA Record</strong>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div>
                            <Label htmlFor="name">Name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="name"
                                    name="name"
                                    placeholder="Display Name"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="ipAddress">IPv6 Address</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="ipAddress"
                                    name="ipAddress"
                                    placeholder="ec0:0ba9:80cd:affe"
                                    value={address}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-4 pt-2">
                        <div>
                            <Label htmlFor="load-balancing-strategy">Health-Check Strategy</Label>
                            <div className="mt-1">
                                <Select id="load-balancing-strategy" required value={healthCheckStrategy}
                                        onChange={(e) => setHealthCheckStrategy(e.target.value)}>
                                    <option value="PING">Ping</option>
                                    <option value="HTTP">HTTP</option>
                                </Select>
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="continent">Service Region</Label>
                            <div className="mt-1">
                                <Select id="load-balancing-strategy" required value={continent}
                                        onChange={(e) => setContinent(e.target.value)}>
                                    <option value="">Global</option>
                                    <option value="EUROPE">Europe</option>
                                    <option value="ASIA">Asia</option>
                                    <option value="AFRICA">Africa</option>
                                    <option value="NORTH_AMERICA">North America</option>
                                    <option value="SOUTH_AMERICA">South America</option>
                                    <option value="OCEANIA">Oceania</option>
                                    <option value="ANTARCTICA">Antarctica</option>

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-4 pt-2">
                        <div>
                            <Label htmlFor="ttl">TTL</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="ttl"
                                    name="ttl"
                                    placeholder="60"
                                    value={ttl}
                                    onChange={e => setTTL(parseInt(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className="pt-7 flex items-center justify-center">
                            <ToggleSwitch checked={active} label="Active" onChange={setActive}/>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex space-x-3">
                        <Button color="blue" onClick={onRecordUpdate}>
                            Update
                        </Button>
                        <Button color="red" outline onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const AddCNAMERecordModal = ({domainId, setDomain, isOpen, setOpen}) => {
    const authHeader = useAuthHeader();
    const navigate = useNavigate();

    const [name, setName] = useState('')
    const [alias, setAlias] = useState('')

    const [ttl, setTTL] = useState(60);
    const [active, setActive] = useState(false);

    const onRecordCreate = async (e: any) => {
        e.preventDefault();

        const data = {
            name: name,
            type: "CNAME",
            ttl: ttl,
            active: active,
            alias: alias
        }

        // Omit location if continent is global
        createRecord(authHeader, domainId, data).then(() => {
            toast.success("Record has been created.");
            setOpen(false);

            // Update domain list
            getDomain(authHeader, domainId)
                .then(result => setDomain(result))
                .catch(() => {
                    navigate("/auth/sign-in");
                    toast.error("Your session has expired.");
                })
        }).catch(error => {
            toast.error("Failed creating record: " + error.message);
        })
    }

    return (
        <>
            <Modal onClose={() => setOpen(false)} show={isOpen}>
                <Modal.Header className="border-b border-gray-200 !p-6">
                    <strong>Create new CNAME Record</strong>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div>
                            <Label htmlFor="name">Name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="name"
                                    name="name"
                                    placeholder="Display Name"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="alias">Alias</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="alias"
                                    name="alias"
                                    placeholder="alias.example.com"
                                    value={alias}
                                    onChange={e => setAlias(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-4 pt-2">
                        <div>
                            <Label htmlFor="ttl">TTL</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="ttl"
                                    name="ttl"
                                    placeholder="60"
                                    value={ttl}
                                    onChange={e => setTTL(parseInt(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className="pt-7 flex items-center justify-center">
                            <ToggleSwitch checked={active} label="Active" onChange={setActive}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex space-x-3">
                        <Button color="blue" onClick={onRecordCreate}>
                            Create
                        </Button>
                        <Button color="red" outline onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const EditCNAMERecordModal = ({domainId, record, setDomain}) => {
    const authHeader = useAuthHeader();
    const navigate = useNavigate();

    const [isOpen, setOpen] = useState(false);

    const [name, setName] = useState(record.name)
    const [alias, setAlias] = useState(record.alias)

    const [ttl, setTTL] = useState(record.ttl);
    const [active, setActive] = useState(record.active);


    const onRecordUpdate = async (e: any) => {
        e.preventDefault();

        const data = {
            name: name,
            ttl: ttl,
            active: active
        }

        updateRecord(authHeader, domainId, record._id, data).then(() => {
            toast.success("Record has been updated.");
            setOpen(false);

            // Update domain list
            getDomain(authHeader, domainId)
                .then(result => setDomain(result))
                .catch(() => {
                    navigate("/auth/sign-in");
                    toast.error("Your session has expired.");
                })
        }).catch(error => {
            toast.error("Failed updating record: " + error.message);
        })
    }

    return (
        <>
            <Button color="green" onClick={() => setOpen(true)}>
                <div className="flex items-center">
                    <HiOutlinePencilAlt className="text-lg"/>
                    Edit Record
                </div>
            </Button>
            <Modal onClose={() => setOpen(false)} show={isOpen}>
                <Modal.Header className="border-b border-gray-200 !p-6">
                    <strong>Edit CNAME Record</strong>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div>
                            <Label htmlFor="name">Name</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="name"
                                    name="name"
                                    placeholder="Display Name"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="alias">Alias</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="alias"
                                    name="alias"
                                    placeholder="alias.example.com"
                                    value={alias}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-4 pt-2">
                        <div>
                            <Label htmlFor="ttl">TTL</Label>
                            <div className="mt-1">
                                <TextInput
                                    id="ttl"
                                    name="ttl"
                                    placeholder="60"
                                    value={ttl}
                                    onChange={e => setTTL(parseInt(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className="pt-7 flex items-center justify-center">
                            <ToggleSwitch checked={active} label="Active" onChange={setActive}/>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex space-x-3">
                        <Button color="blue" onClick={onRecordUpdate}>
                            Update
                        </Button>
                        <Button color="red" outline onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const DeleteRecordModal = ({domainId, record, setDomain}) => {
    const authHeader = useAuthHeader();
    const navigate = useNavigate();

    const [isOpen, setOpen] = useState(false);

    const onRecordDelete = async (e: any) => {
        e.preventDefault();

        deleteRecord(authHeader, domainId, record._id).then(() => {
            toast.success("Record has been deleted.");
            setOpen(false);

            // Update domain list
            getDomain(authHeader, domainId)
                .then(result => setDomain(result))
                .catch(() => {
                    navigate("/auth/sign-in");
                    toast.error("Your session has expired.");
                })
        }).catch(error => {
            toast.error("Failed deleting record: " + error.message);
        })
    }

    return (
        <>
            <Button color="failure" onClick={() => setOpen(true)}>
                <div className="flex items-center">
                    <HiTrash className="text-lg"/>
                    Delete Record
                </div>
            </Button>
            <Modal onClose={() => setOpen(false)} show={isOpen} size="md">
                <Modal.Header className="px-6 pt-6 pb-0">
                    <span className="sr-only">Delete Domain</span>
                </Modal.Header>
                <Modal.Body className="px-6 pt-0 pb-6">
                    <div className="flex flex-col items-center gap-y-6 text-center">
                        <HiOutlineExclamationCircle className="text-7xl text-red-500"/>
                        <p className="text-xl text-gray-500">
                            Are you sure you want to delete record '{record.name}'?
                        </p>
                        <div className="flex items-center gap-x-3">
                            <Button color="failure" onClick={onRecordDelete}>
                                Yes, I'm sure
                            </Button>
                            <Button color="gray" onClick={() => setOpen(false)}>
                                No, cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DomainRecords;
