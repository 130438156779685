export interface UserCreate {
    first_name: string,
    last_name: string,
    email: string,

    username: string,
    password: string,
}

export interface UserUpdate {
    first_name: string,
    last_name: string,
    email: string,

    username: string,
    password?: string,

    administrator: boolean,

    meta: UserMetaData,
}

interface UserMetaData {
    position: string,
    country: string
}

export function isPasswordValid(password: string, passwordRepeat: string) {
    return !(password === "" || password.length < 10 || password !== passwordRepeat);
}