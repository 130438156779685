import type { CustomFlowbiteTheme } from "flowbite-react";

const flowbiteTheme: CustomFlowbiteTheme = {
    navbar: {
        root: {
            base: "fixed w-full bg-white px-2 py-2.5 z-30 border-b border-gray-200",
        }
    },
    sidebar: {
        root: {
            base: "h-full z-20 border-r pt-16 border-gray-200",
            inner: "h-full overflow-y-auto overflow-x-hidden bg-white px-3 py-4"
        }
    },
    modal: {
        content: {
            inner: "relative rounded-lg bg-white shadow"
        },
        header: {
            base: "flex items-center justify-between rounded-t px-5 pt-5"
        }
    },
    button: {
        outline: {
            on: "transition-all duration-75 ease-in group-hover:bg-opacity-0 group-hover:text-inherit",
        },
        size: {
            md: "text-sm px-3 py-2",
        },
    },
    clipboard: {
        withIconText: {
            base: "inline-flex items-center justify-center rounded-lg border border-gray-200 bg-white px-2.5 py-2 text-gray-900 hover:bg-gray-100"
        }
    },
    textInput: {
        field: {
            input: {
                base: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 font-medium placeholder:font-light"
            }
        }
    }
}

export default flowbiteTheme;